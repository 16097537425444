const ServiceWorkerUpdateNotification = ({ updateApp }) => {
	return (
		<div style={styles.container}>
			<p style={styles.message}>
				A new version of the app is available!
				<button
					style={styles.button}
					onClick={updateApp}
				>
					Update
				</button>
			</p>
		</div>
	);
};

const styles = {
	container: {
		position: "fixed",
		bottom: "20px",
		left: "50%",
		transform: "translateX(-50%)",
		backgroundColor: "#333",
		color: "#fff",
		padding: "10px 20px",
		borderRadius: "5px",
		zIndex: 99999
	},
	message: {
		margin: 0
	},
	button: {
		marginLeft: "10px",
		padding: "5px 10px",
		backgroundColor: "#007bff",
		border: "none",
		color: "#fff",
		borderRadius: "3px",
		cursor: "pointer"
	}
};

export default ServiceWorkerUpdateNotification;
